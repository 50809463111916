const cardList = [{
    text: "Out of the people here, who do you trust the least and why?"},

{text: "What was the last thing I did that really pissed you off?"},

{text: "What is one thing that annoys you about everyone in the room?"},

{text: "When’s the last time I made you cry?"},

{text: "If you had to sleep with one player in the room, who would it be and why?"},

{text: "What’s one thing you dislike about me, if you wish to speak about another player— drink "},

{text: "Give me unsolicited advice that I need to hear"},

{text: "Which friend of yours/person we all know would you like to see me with and why?"},

{text: "F.M.K ?— three players of asker’s choosing"},

{text: "What’s one secret you’ve kept from me?"},

{text: "Rank all the players from closest friend to least, no explanation— if a player wants to know why they were ranked, they must take a sip"},

{text: "Which player’s parent(s) do you like most & least ?"},

{text: "In a moment of crisis which player would be least helpful?"},

{text: "Give me your most honest opinion of me."},

{text: "What is your most controversial take?"},

{text: "Drink your body count."},

{text: "rank players from most to least annoying, no explanation— if a player wants to know why they were ranked, they must take a sip"},

{text: "who’s the most annoying player when in a relationship?"},

{text: "most likely to turn on the group?"},

{text: "one thing you would change about each player?"},

{text: "Where would you rather be then here?"},

{text: "Out of all the players in the group, who had the worst ex?"},

{text: "Let all the players compose a text and send it to a random pick from your last 10 messaged contacts."},

{text: "Which of the players would you have been friends with in high school? Which of them wouldn’t you have been?"},

{text: "Let all other players tell you your toxic trait."},

{text: "When was the last time you lied to one of us, what about?"},

{text: "Play a game of broken telephone with a secret you may/may not want to share with the person to your left."},

{text: "Show a crazy photo you have of one of us in your camera roll."},

{text: "Truth or drink!"},

{text: "You have to watch three movies for the rest of your life, which three are you picking?"},

{text: "FMK: Three people of groups choosing."},

{text: "Head to Head: The rest of the group picks a topic at random, i.e. Countries that end with ‘A’, asker and player have to go head to head. Loser drinks."},

{text: "What is the weirdest thing you find attractive on a person, if the group votes it’s too weird— finish your drink."},

{text: "Odds on: If asker and player, say the same number on the count of three, player drinks. If not, asker picks something for player to do."},

{text: "What’s your worst memory with me?"},

{text: "Who would you rather not be in this room right now?"},

{text: "Would you rather?"},

{text: "Most recent thing in your search history?"},

{text: "Last person you looked up on instagram/twitter/etc. ?"},

{text: "Have you ever flirted with one of your friend’s significant others?"},

{text: "Who in the group would have the best Only Fans, and would you be a subscriber?"},

{text: "How many sex toys do you have?"},

{text: "What is the player’s, self-described most toxic trait?"},

{text: "what would your younger self not believe about your life today?"},

{text: "what is your favourite adjective with which to be described and why?"},

{text: "what's the hardest part about dating you?"},

{text: "what would be the hardest part about dating the asker?"},

{text: "what's the biggest mistake you've made?"},

{text: "Name a similarity between the asker and the player."},

{text: "Chug it: Asker and player compete to see who finishes their drink first."},

{text: "Who has the best sense of humour in this room?"},

{text: "What’s the most hungover you’ve ever been?"},

{text: "What’s your go-to karaoke song?"},

{text: "What’s your guilty pleasure TV show?"},

{text: "Wild Card: Asker can ask anything they like?"},

{text: "Biggest ick in a relationship?"},

{text: "Biggest ick in a friendship?"},

{text: "Do you believe in soul mates?"},

{text: "Who do you stalk the most on social media?"},

{text: "What’s the meanest thing you’ve ever said to someone?"},

{text: "What’s the meanest thing you’ve ever said to a player in this room?"},

{text: "What’s your biggest green flag in a partner? If the group agrees then the group drinks."},

{text: "What’s your biggest red flag in a partner? If the group agrees then the group drinks."},

{text: "One round of never have I ever, if you have, drink."},

{text: "Compliment circle; say one nice thing about every player."},

{text: "List three words that describe the asker?"},

{text: "Dare or drink!"},

{text: "Oldest player in the group drinks."},

{text: "Heardle: Asker shuffles their music library and player has 30 seconds to guess what the song is. If they don’t get it in 30 seconds, both players drinks. If they do, asker drinks."},

{text: "What’s the weirdest thing about me?"},

{text: "Would I join a cult?"},

{text: "What is your least favourite personality trait about the asker?"},

{text: "What is your least favourite personality trait about someone in the group?"},

{text: "What is your least favourite thing about someone of the asker’s choosing?"},

{text: "Which one of your parent's personality traits do you want to keep/let go of?"},

{text: "What about me surprised you when we first met?"},

{text: "Admit something you’ve been meaning to get off your chest."},

{text: "Write a tinder bio for the asker."},

{text: "Both players share your screen time, whoever’s is highest— drink."},

{text: "What movie do you wish you could watch again for the first time, why?"},

{text: "What was your best night out ever? Describe it."},

{text: "Who in the group do you think you can be the most vulnerable with?"},

{text:"What has been your happiest memory this past year?"},

{text: "Let the group decide what they think the player’s main love language is?"},

{text: "What is the hardest truth you had to face this year?"},

{text: "What is your favourite memory we have together?"},

{text: "What has being single/in a relationship taught you about yourself, if anything?"},

{text: "Do you think you fall in love easily? Why or a why not?"},

{text: "Who’s one person I remind you off, fictional, famous, or whatever?"},

{text: "Pick a city I should move to, why would I thrive there?"},

{text: "Am I at all like what you expected me to be when we first met?"},

{text: "Waterfall, start with the asker."},

{text: "Asker don’t announce this card to the room. The last person to touch their nose takes a drink."},

{text: "Cheers! Everyone drink."},

{text: "Russian roulette, put all names into a name wheel and pick at random. Whoever pops, take a shot!"},

{text: "Doing the count: The player counts “one, two, three” and on four, all players put a number on their head. Players who have the same number as the player whose turn it is can drink."},

{text: "Jungle Juice: All player pour some of their drink into an empty glass, put all names into a name wheel and pick at random. Whoever pops, finish the glass!"},

{text: "Blind Karaoke: Let the asker pick a song for you to sing, either turn away from the screen or close your eyes and try to sing the first 60 seconds of it."},

{text: "Categories: Player must pick a category. Then each player must choose something that fits into that category. Whoever can’t think of anything or makes a repeat must down two fingers of drink." },

{text: "Pick a player most likely to never get married."},

{text: "Pick a player who changed the most since you met them."},

{text: "Speech & Debate: Asker picks another player to rant  with current player for 30 seconds on a topic of Askers choosing."}
]

function getRandomItem() {
    const randomIndex = Math.floor(Math.random() * cardList.length);
    return cardList[randomIndex];
}

export { cardList, getRandomItem}